<template>
  <div
    v-if="
      getUser.role.slug === 'super-administrateur' ||
        getUser.role.slug === 'responsable-relation-client'
    "
  >
    <b-card title="STATISTIQUES POUR CC">
      <div
        v-if="isAdminOrderListLoading"
        class="text-center text-info my-2"
      >
        <b-spinner
          variant="info"
          style="width: 3rem; height: 3rem;"
        />
      </div>

      <app-collapse
        v-else
        accordion
        type="margin"
      >

        <app-collapse-item
          v-for="(cc, index) in getAdminOrderList"
          :key="index"
          :title="cc.full_name"
          @collapse-click="getAdminOrderKpis(cc.id)"
        >

          <b-row class="justify-content-center mx-1 mb-3">
            <b-col
              sm="9"
              xs="12"
            >
              <b-form-group>
                <h5>Filtrer par date</h5>
                <flat-pickr
                  v-model="rangeDate"
                  class="form-control"
                  :config="config"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="3"
              xs="12"
              class="mt-2"
            >
              <b-button
                :disabled="isRangeStatisticsLoading || rangeDate == null"
                variant="primary"
                @click="
                  rangeDate = null;
                  filterIsActive = false;
                  getAdminOrderKpis(cc.id)
                "
              >
                {{ isRangeStatisticsLoading ? "Chargement..." : "Effacer" }}
              </b-button>
            </b-col>
          </b-row>

          <div
            v-if="isAdminOrderKpisLoading"
            class="text-center text-info my-2"
          >
            <b-spinner
              variant="info"
              style="width: 3rem; height: 3rem;"
            />
          </div>

          <b-row v-else>
            <b-col
              v-for="(item , index) in Object.entries(statisticsItems)"
              :key="index"
              lg="4"
              sm="6"
              style="height:max-content !important;"
            >
              <statistic-card-horizontal
                :icon="
                  item[0]==='numberOfActiveContracts' ? 'PowerIcon':
                  item[0]==='commissionPunctualOrder' ? 'RotateCwIcon':
                  item[0]==='numberOfCompletedPunctualOrder' ? 'CheckIcon':
                  item[0]==='numberOfEmployeesDeployed' ? 'UserIcon':
                  item[0]==='numberOfFinishedContract' ? 'ShieldOffIcon':
                  item[0]==='numberOfPunctualOrder' ? 'RotateCwIcon':
                  item[0]==='numberOfRecurringOrderBroughtByCC' ? 'RepeatIcon':
                  item[0]==='numberOfRegisteredCustomers' ? 'UsersIcon':
                  item[0]==='numberOfRegisteredPros' ? 'UsersIcon':
                  item[0]==='numberOfSuspendedContract' ? 'DeleteIcon':
                  item[0]==='numberOfEmployeesRegistered' ? 'UserIcon':
                  item[0]==='numberOfEmployeesValidated' ? 'UserCheckIcon':
                  item[0]==='numberOfEmployeesWhoQuitTheirJobs'? 'UserMinusIcon' :
                  item[0]==='numberOfRecurringOrder'? 'RepeatIcon':
                  ''"
                color="info"
                :statistic="item[1].toLocaleString()"
                :statistic-title="
                  item[0]==='numberOfActiveContracts' ? 'Contrat(s) actif(s)':
                  item[0]==='numberOfEmployeesWhoQuitTheirJobs'? 'Employé(s) désisté(s)' :
                  item[0]==='numberOfFinishedContract' ? 'Contrat(s) clôturé(s)':
                  item[0]==='numberOfRecurringOrder'? 'Commande(s) récurrente(s)':
                  item[0]==='numberOfSuspendedContract' ? 'Contrat(s) résilié(s)':

                  item[0]==='numberOfRecurringOrderBroughtByCC' ? 'Commandes récurrentes apportées':
                  item[0]==='commissionPunctualOrder' ? 'Commandes ponctuel':
                  item[0]==='numberOfCompletedPunctualOrder' ? 'Commandes ponctuelles terminées':

                  ''"
              />
            </b-col>

          </b-row>

        </app-collapse-item>

      </app-collapse>
    </b-card>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCol,
  BRow,
  BFormGroup,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    BCard,
    BCardText,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCol,
    BRow,
    BFormGroup,
    flatPickr,
    BButton,
    BSpinner,
    AppCollapse,
    AppCollapseItem,
    StatisticCardHorizontal,
  },

  data() {
    return {
      rangeDate: null,
      filterIsActive: false,
      isRangeStatisticsLoading: false,
      config: { mode: 'range' },
      isAdminOrderKpisLoading: true,

      ccId: null,
      statisticsItems: [],
    }
  },

  computed: {
    ...mapGetters('roles', ['getBusinessManagersList', 'getAdminOrderList']),
    ...mapGetters('auth', ['getUser']),
    ...mapGetters(['isBusinessManagersListLoading', 'isAdminOrderListLoading']),

  },

  watch: {
    rangeDate(val) {
      if (val && val.split('to').length === 2) {
        this.applyGetRangeStatisticsAction(val)
      }
    },
  },

  created() {

  },

  methods: {
    ...mapActions('statistics', [
      'getAdminOrderStatisticsByRangeAction',
      'getAdminOrderStatisticsAction',
    ]),

    getAdminOrderKpis(id) {
      this.ccId = id
      this.rangeDate = null
      this.filterIsActive = false
      this.isAdminOrderKpisLoading = true
      this.getAdminOrderStatisticsAction(id)
        .then(response => {
          this.isAdminOrderKpisLoading = false
          this.statisticsItems = response.data
        })
    },

    applyGetRangeStatisticsAction(val) {
      const payload = {
        start_date: val.split('to')[0],
        end_date: val.split('to')[1],
      }
      this.isRangeStatisticsLoading = true
      this.isAdminOrderKpisLoading = true
      this.getAdminOrderStatisticsByRangeAction({ ccId: this.ccId, payload })
        .then(response => {
          this.isRangeStatisticsLoading = false
          this.isAdminOrderKpisLoading = false
          this.statisticsItems = response.data
          this.filterIsActive = true
        })
        .catch(error => {
          this.isRangeStatisticsLoading = false
          this.isAdminOrderKpisLoading = false
          if (error.response.status !== 422) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: 'Erreur lors du filtrage, Réessayer!',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
